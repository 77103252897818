import { render, staticRenderFns } from "./MovieDetails.vue?vue&type=template&id=a0fef240&scoped=true&"
import script from "./MovieDetails.vue?vue&type=script&lang=js&"
export * from "./MovieDetails.vue?vue&type=script&lang=js&"
import style0 from "./MovieDetails.vue?vue&type=style&index=0&id=a0fef240&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0fef240",
  null
  
)

export default component.exports
import {QResponsive,QSkeleton,QBadge,QIcon,QBtnGroup,QBtn,QExpansionItem,QItemSection,QAvatar,QItemLabel,QItem,QSlider,QSpinnerDots,QList,QDialog,QCard,QCardSection,QInput,QCardActions,QSpace,QCheckbox,QForm,QOptionGroup,Ripple,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QResponsive,QSkeleton,QBadge,QIcon,QBtnGroup,QBtn,QExpansionItem,QItemSection,QAvatar,QItemLabel,QItem,QSlider,QSpinnerDots,QList,QDialog,QCard,QCardSection,QInput,QCardActions,QSpace,QCheckbox,QForm,QOptionGroup})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple,ClosePopup})
