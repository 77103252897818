//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showUser: {
      type: Boolean,
      default: false,
    },
    showMovieLink: {
      type: Boolean,
      default: true,
    },
    reviews: {
      type: Array,
      default() {
        return [];
      },
    },
    emptyTitle: {
      type: String,
      default: "Nothing to show here",
    },
    emptyDesc: {
      type: String,
      default: "No Reviews found!",
    },
    emptyIcon: {
      type: String,
      default: null,
    },
    emptyImage: {
      type: String,
      default: null,
    },
    emptyTopPadded: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    get_like_txt(count) {
      if (count == 0) return "no likes";
      if (count == 1) return "1 like";
      return `${count} likes`;
    },
    get_like_btn_color(liked_by) {
      return this.if_i_liked(liked_by) ? "primary" : "default";
    },
    if_i_liked(liked_by) {
      var liked = false;
      if (!this.my_profile) return liked;

      liked_by.forEach((user) => {
        if (user.id == this.my_profile.id) {
          liked = true;
        }
      });
      return liked;
    },
  },
};
