//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "You need to sign in before doing that",
    },
    icon: {
      type: String,
      default: "mdi-alert-circle-outline",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
